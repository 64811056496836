@import '../../accaIntro.module.scss';

.flowListItem {
  display: flex;
}

.videoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 180px;
  border-radius: 16px;
  background-size: cover;

  .playBtn {
    flex: none;
    width: 80px;
    height: 80px;
    background-image: url(../../@assets/playOn.png);
    background-size: cover;
  }
}

.videoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  .storyMent {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.16px;
    color: $C_COOL_GRAY_70;
    white-space: pre-wrap;
    text-align: center;

    strong {
      font-weight: 700;
      color: $C_JOBDA_BLACK;
    }
  }

  .interviewJob {
    margin-top: 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    color: $C_COOL_GRAY_70;
  }

  .interviewer {
    @include fontStyle($B2P_B, $C_JOBDA_BLACK);
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }
}
